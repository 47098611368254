<template>
  <main id="full" class="">
    <section class="section-top point">
      <h1 class="search-form__title">輸入&nbsp;6&nbsp;位數驗證碼</h1>
      <p class="section-top-text">驗證碼已傳送至{{ $route.params.phone }}</p>
    </section>
    <section class="section-main py-1">
      <form @submit.prevent="handleSubmit">
        <ul class="SMSverify-form" :class="{ 'form-shake': isCodeError }">
          <li class="smsverifyArea">
            <span
              class="smsverifyArea-item"
              v-for="(item, index) in verifyCode"
              :key="index"
            >
              <input
                type="number"
                :disabled="isExpire"
                :data-index="index + 1"
                inputmode="tel"
                required
                maxlength="1"
                v-model="verifyCode[index]"
                autofocus
                @input="handleInputLength($event)"
                @keyup.prevent="handleNextNumber($event)"
              />
            </span>
          </li>
          <div class="message-static is-danger" v-if="isCodeError === true">
            <p class=""><strong>請輸入正確驗證碼</strong><br /></p>
          </div>
          <div class="submit form-item">
            <button
              v-if="!isExpire"
              type="submit"
              class="button is-rounded is-fullwidth is-outlined"
              @click="handleSubmit"
            >
              {{ isProcessing ? "驗證中..." : "確認送出" }}
            </button>
            <button
              disabled
              v-if="isExpire"
              class="button is-rounded is-fullwidth is-outlined disabled"
            >
              確認送出
            </button>
          </div>
          <div class="tipsBox">
            <div class="countdownTik">
              {{ timeFormat }}
            </div>
            <div
              class="noRecieve"
              v-if="isNoRecieve"
              @click="handleResendVerifyCode"
            >
              <button>重發驗證碼</button>
            </div>
          </div>
        </ul>
      </form>
      <div class="message-box" v-if="showResendVerifyCodeSuccess === true">
        <p>已重新發送驗證碼，請檢查您的手機訊息。</p>
      </div>
    </section>
  </main>
</template>
<script>
import verifyAPI from "../../apis/verify.js";
import dayjs from "dayjs";

export default {
  name: "SMSverify",
  data() {
    return {
      isProcessing: false,
      phone: "",
      cardNo: "",
      check: false,
      phoneValid: false,
      phoneDefault: true,
      cardValid: false,
      cardDefault: true,
      isCodeError: false,
      isNoRecieve: false,
      isExpire: false,
      showResendVerifyCodeSuccess: false,
      verifyCode: Array(6),
      countdown: 0,
      timer: null,
      verifyData: null,
      currentUserId: null,
      cardSearialNumber: null,
    };
  },
  mounted() {
    if (this.$route.params.phone == undefined) {
      this.$router.push({
        name: "CardLink",
      });
      return;
    }
    this.verifyData = this.$route.params.verifyData;
    this.cardNo = this.$route.params.cardNo;
    this.phone = this.$route.params.phone;
    const expire = dayjs().unix() + 120;
    this.timer = setInterval(() => {
      this.countdown = expire - dayjs().unix();
    }, 1000);
    document.getElementsByTagName("input")[0].focus();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    handleInputLength(e) {
      let value = e.target.value;
      const index = e.target.dataset.index;
      if (value.length > 1) {
        this.verifyCode[index - 1] = value.slice(
          value.length - 1,
          value.length + 1
        );
      }
    },
    handleNextNumber(e) {
      this.isCodeError = false;
      var inputArr = document.getElementsByTagName("input");
      const index = e.target.dataset.index;
      const reg = /^[0-9\s]*$/;
      const result = reg.test(e.key);

      if (e.key === "Backspace") {
        this.verifyCode = Array(6);
        this.isCodeError = false;
        inputArr[0].focus();
        return;
      }

      if (!result) {
        this.verifyCode[index - 1] = "";
        e.target.value = "";
        return;
      }

      if (index < 6) {
        inputArr[index].focus();
        return;
      }

      if (index == 6) {
        inputArr[5].blur();
        this.handleSubmit();
        return;
      }
    },
    async handleSubmit() {
      this.isCodeError = false;
      if (this.verifyCode.join("").length !== 6) {
        this.isCodeError = true;
        setTimeout(() => {
          this.isCodeError = false;
        }, 3000);
        return;
      }
      try {
        this.isProcessing = true;
        const phonenumberverificationid =
          this.verifyData.phoneNumberVerificationId;
        const payload = {
          sourceid: this.verifyData.sourceId,
          code: this.verifyCode.join(""),
        };

        const { data, status } = await verifyAPI.SMSverify.verifyCode(
          phonenumberverificationid,
          payload
        );

        if (!(status >= 200 && status < 300)) {
          this.isProcessing = false;
          this.isCodeError = true;
          setTimeout(() => {
            this.isCodeError = false;
          }, 3000);
          throw new Error(status);
        }

        if (data.verified === 1) {
          this.$router.push({
            name: "BindingSuccess",
            params: { isVerify: true },
          });
        }
      } catch (error) {
        this.isProcessing = false;
        this.isCodeError = true;
        this.verifyCode = Array(6);

        document.getElementsByTagName("input")[0].focus();

        console.log(error);
      }
    },
    async handleResendVerifyCode() {
      try {
        this.resetAll();
        const { data, status } = await verifyAPI.SMSverify.verifyExist({
          cardnumber: this.cardNo,
          phonenumber: this.phone,
        });
        if (!(status >= 200 && status < 300)) {
          this.isProcessing = false;
          this.isCodeError = true;
          throw new Error(status);
        }
        this.verifyData = data;
      } catch (error) {
        this.isProcessing = false;
        this.isCodeError = true;
        console.log(error);
      }
    },
    setCountdown: function () {
      setInterval(() => {
        this.countdown--;
      }, 1000);
    },
    resetAll() {
      clearInterval(this.timer);
      this.verifyCode = Array(6);
      this.isCodeError = false;
      this.isExpire = false;

      const expire = dayjs().unix() + 120;

      this.timer = setInterval(() => {
        this.countdown = expire - dayjs().unix();
      }, 1000);

      this.showResendVerifyCodeSuccess = true;
      this.isNoRecieve = false;

      setTimeout(() => {
        this.showResendVerifyCodeSuccess = false;
      }, 5000);
    },
  },
  watch: {
    countdown() {
      if (this.countdown == 0) {
        clearInterval(this.timer);
        this.isNoRecieve = true;
        this.isExpire = true;
      }
    },
  },
  computed: {
    timeFormat() {
      return dayjs(this.countdown * 1000).format("mm:ss");
    },
  },
};
</script>
