import { apiHelper } from "../utils/helpers";

export default {
  SMSverify: {
    verifyExist(payload) {
      return apiHelper.post(
        `/chargingcard/partnerchargingcard/verify/`,
        payload
      );
    },
    verifyCode(phonenumberverificationid, payload) {
      return apiHelper.patch(
        `/chargingcard/partnerchargingcard/verify/?phonenumberverificationid=${phonenumberverificationid}`,
        payload
      );
    },
  },
};
